// FAQ Layout and Contact Block
.custom-background {
    &.background-white {
        .faqs__contact-wrapper {
            background: $wild-sand;
        }
    }

    &.background-grey {
        .faqs__contact-wrapper {
            background: $white;
        }
    }
}

.faqs__wrapper {
    max-width: 1312px;
    margin: 0 auto;
    padding: 28px 0;

    @include mq($not-mobile-tablet-pt) {
        padding: 44px 40px;
    }
}

.faqs__content {
    display: flex;
    flex-direction: column;

    @include mq($not-mobile-tablet-pt) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.faqs__left,
.faqs__right {
    width: 100%;

    @include mq($not-mobile-tablet-pt) {
        width: calc(50% - 8px);
    }
}

.faqs__left {
    padding: 0 16px;
    margin-bottom: 32px;

    @include mq($not-mobile-tablet-pt) {
        padding: 0;
        margin-right: 48px;
    }
}

.faqs__right {
    display: flex;
    height: fit-content;

    @include mq($not-mobile-tablet-pt) {
        justify-content: flex-end;
    }
}

.faqs__title {
    font-size: 40px;
    line-height: 48px;
    padding: 0 16px;
    margin-bottom: 16px;

    @include mq($not-mobile-tablet-pt) {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 32px;
        padding: 0;
    }
}

.faqs__link {
    font-family: $heading-pro-treble-extra-bold;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;

    .arrow-right {
        &::before {
            content: svg(arrow-right);
            @include iconfont-styles;

            display: inline-block;
            color: $safety-orange;
            font-size: 21px;
            transform: rotate(45deg);
            vertical-align: middle;
            margin-left: 10px;
        }
    }
}

.faqs__contact-wrapper {
    background-color: $wild-sand;
    width: 100%;
    padding: 40px 16px;

    @include mq($not-mobile-tablet-pt) {
        max-width: 448px;
        padding: 40px;
    }
}

.faqs__contact-title-wrapper {
    display: flex;
    flex-direction: column;

    @include mq($not-mobile-tablet-pt) {
        flex-direction: row;
        font-size: 32px;
    }
}

.faqs__contact-icon {
    &::before {
        content: "";
        background: url("../../../img/svg/informational.svg") no-repeat;
        width: 40px;
        height: 34px;
        display: inline-block;
        background-size: contain;
        margin-right: 16px;
    }
}

.faqs__contact-title {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 8px;
}

.faqs__contact-description {
    margin-bottom: 24px;
}

.faqs__contact-button {
    display: block;
}

// FAQ Accordion
.accordions-general {
    .faq__block,
    .faq__inner {
        padding: 0;
    }

    .faq__item {
        border-bottom: 1px solid $grey-main-middle;

        &:empty {
            display: none;
        }

        &.open {
            .faq__question {
                padding-bottom: 8px;
                margin-bottom: 0;
            }

            .faq__icon {
                transform: none;
                top: 10px;

                &::before {
                    content: "";
                    height: 0;
                    margin-top: 0;
                }

                &::after {
                    @include mq($tablet-max) {
                        opacity: 1;
                    }
                }
            }
        }

        @include mq($not-mobile-tablet) {
            padding-bottom: 0;
            margin-bottom: 20px;
        }
    }

    .faq__question {
        border: 0;
        padding-bottom: 0;
        margin-bottom: 20px;

        @include mq($tablet-max) {
            border: 0;
            margin-bottom: 20px;
            padding-bottom: 0;
        }

        @include mq($not-mobile-tablet) {
            margin-bottom: 20px;
        }
    }

    .faq__button {
        font-family: $heading-pro-treble-extra-bold;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: normal;
        background: none;
        border: 0;
        padding: 0;
        position: relative;
        text-align: left;
        width: 100%;
    }

    .faq__label {
        display: block;
        word-break: break-word;
        overflow: hidden;
        width: 90%;
    }

    .faq__info {
        font-family: $heading-pro-double-regular;
        font-size: 14px;
        line-height: 18px;
        text-align: start;

        p {
            margin-bottom: 20px;
        }

        a {
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
            text-transform: none;
        }
    }

    // Plus and minus icon with animation in CSS
    .faq__icon {
        top: 10px;

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 40%;
            right: 0;
            margin: 0 0 -8px;
            background: $nero;
            border-radius: 2px;
        }

        &::before {
            right: 7px;
            width: 3px;
            height: 15px;
            transition: ease-in-out 0.3s;
            margin-top: -7.5px;
        }

        &::after {
            right: 1px;
            width: 15px;
            height: 3px;
            margin-top: -1.5px;
        }

        @include mq($tablet-max) {
            width: inherit;
            height: inherit;
            top: 10px;

            &::after {
                transform: none;
            }
        }
    }
}
