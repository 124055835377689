/** font family **/
@media only screen and (min-width: 768px) {
  /*! "$not-mobile" */
}
@media only screen and (min-width: 901px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (min-width: 1025px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (max-width: 1599px) {
  /*! "until-dt-max" */
}
@media only screen and (max-width: 1366px) {
  /*! "until-dt-med" */
}
@media only screen and (max-width: 1279px) {
  /*! "until-dt-min" */
}
@media only screen and (min-width: 1280px) {
  /*! "dt-min" */
}
@media only screen and (max-width: 1024px) {
  /*! "tablet-max" */
}
@media only screen and (min-width: 901px) and (max-width: 1024px) {
  /*! "tablet-ls" */
}
@media only screen and (max-width: 900px) {
  /*! "mobile-tablet-pt" */
}
@media only screen and (min-width: 768px) and (max-width: 900px) {
  /*! "tablet-pt" */
}
@media only screen and (max-width: 767px) {
  /*! "$mobile-max" */
}
@media only screen and (max-width: 415px) {
  /*! "$mobile-pt" */
}
.custom-background.background-white .faqs__contact-wrapper {
  background: #ece9e8;
}
.custom-background.background-grey .faqs__contact-wrapper {
  background: #fff;
}

.faqs__wrapper {
  max-width: 1312px;
  margin: 0 auto;
  padding: 28px 0;
}
@media only screen and (min-width: 901px) {
  .faqs__wrapper {
    padding: 44px 40px;
  }
}

.faqs__content {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 901px) {
  .faqs__content {
    flex-direction: row;
    justify-content: space-between;
  }
}

.faqs__left,
.faqs__right {
  width: 100%;
}
@media only screen and (min-width: 901px) {
  .faqs__left,
  .faqs__right {
    width: calc(50% - 8px);
  }
}

.faqs__left {
  padding: 0 16px;
  margin-bottom: 32px;
}
@media only screen and (min-width: 901px) {
  .faqs__left {
    padding: 0;
    margin-right: 48px;
  }
}

.faqs__right {
  display: flex;
  height: fit-content;
}
@media only screen and (min-width: 901px) {
  .faqs__right {
    justify-content: flex-end;
  }
}

.faqs__title {
  font-size: 40px;
  line-height: 48px;
  padding: 0 16px;
  margin-bottom: 16px;
}
@media only screen and (min-width: 901px) {
  .faqs__title {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 32px;
    padding: 0;
  }
}

.faqs__link {
  font-family: "HeadingProTreble-ExtraBold", sans-serif;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}
.faqs__link .arrow-right::before {
  content: "\f102";
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  color: inherit;
  display: inline-block;
  color: #fe7000;
  font-size: 21px;
  transform: rotate(45deg);
  vertical-align: middle;
  margin-left: 10px;
}

.faqs__contact-wrapper {
  background-color: #ece9e8;
  width: 100%;
  padding: 40px 16px;
}
@media only screen and (min-width: 901px) {
  .faqs__contact-wrapper {
    max-width: 448px;
    padding: 40px;
  }
}

.faqs__contact-title-wrapper {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 901px) {
  .faqs__contact-title-wrapper {
    flex-direction: row;
    font-size: 32px;
  }
}

.faqs__contact-icon::before {
  content: "";
  background: url("../../../img/svg/informational.svg") no-repeat;
  width: 40px;
  height: 34px;
  display: inline-block;
  background-size: contain;
  margin-right: 16px;
}

.faqs__contact-title {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 8px;
}

.faqs__contact-description {
  margin-bottom: 24px;
}

.faqs__contact-button {
  display: block;
}

.accordions-general .faq__block,
.accordions-general .faq__inner {
  padding: 0;
}
.accordions-general .faq__item {
  border-bottom: 1px solid #d8d8d8;
}
.accordions-general .faq__item:empty {
  display: none;
}
.accordions-general .faq__item.open .faq__question {
  padding-bottom: 8px;
  margin-bottom: 0;
}
.accordions-general .faq__item.open .faq__icon {
  transform: none;
  top: 10px;
}
.accordions-general .faq__item.open .faq__icon::before {
  content: "";
  height: 0;
  margin-top: 0;
}
@media only screen and (max-width: 1024px) {
  .accordions-general .faq__item.open .faq__icon::after {
    opacity: 1;
  }
}
@media only screen and (min-width: 1025px) {
  .accordions-general .faq__item {
    padding-bottom: 0;
    margin-bottom: 20px;
  }
}
.accordions-general .faq__question {
  border: 0;
  padding-bottom: 0;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1024px) {
  .accordions-general .faq__question {
    border: 0;
    margin-bottom: 20px;
    padding-bottom: 0;
  }
}
@media only screen and (min-width: 1025px) {
  .accordions-general .faq__question {
    margin-bottom: 20px;
  }
}
.accordions-general .faq__button {
  font-family: "HeadingProTreble-ExtraBold", sans-serif;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: normal;
  background: none;
  border: 0;
  padding: 0;
  position: relative;
  text-align: left;
  width: 100%;
}
.accordions-general .faq__label {
  display: block;
  word-break: break-word;
  overflow: hidden;
  width: 90%;
}
.accordions-general .faq__info {
  font-family: "HeadingProDouble-Regular", sans-serif;
  font-size: 14px;
  line-height: 18px;
  text-align: start;
}
.accordions-general .faq__info p {
  margin-bottom: 20px;
}
.accordions-general .faq__info a {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-transform: none;
}
.accordions-general .faq__icon {
  top: 10px;
}
.accordions-general .faq__icon::before, .accordions-general .faq__icon::after {
  content: "";
  position: absolute;
  top: 40%;
  right: 0;
  margin: 0 0 -8px;
  background: #2d2d2d;
  border-radius: 2px;
}
.accordions-general .faq__icon::before {
  right: 7px;
  width: 3px;
  height: 15px;
  transition: ease-in-out 0.3s;
  margin-top: -7.5px;
}
.accordions-general .faq__icon::after {
  right: 1px;
  width: 15px;
  height: 3px;
  margin-top: -1.5px;
}
@media only screen and (max-width: 1024px) {
  .accordions-general .faq__icon {
    width: inherit;
    height: inherit;
    top: 10px;
  }
  .accordions-general .faq__icon::after {
    transform: none;
  }
}

.experience-homepageredesignassets-layouts-faqs .custom-background.background-white .faqs__contact-wrapper {
  background: #ece9e8;
}
.experience-homepageredesignassets-layouts-faqs .custom-background.background-grey .faqs__contact-wrapper {
  background: #fff;
}

.faqs__contact-icon::before {
  content: "";
  background: url("../../../../img/svg/informational.svg") no-repeat;
  width: 40px;
  height: 34px;
  display: inline-block;
  background-size: contain;
  margin-right: 16px;
}