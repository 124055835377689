@import "../../05-components/faqs";

.experience-homepageredesignassets-layouts-faqs {
    .custom-background {
        &.background-white {
            .faqs__contact-wrapper {
                background: $wild-sand;
            }
        }

        &.background-grey {
            .faqs__contact-wrapper {
                background: $white;
            }
        }
    }
}

.faqs__contact-icon {
    &::before {
        content: "";
        background: url("../../../../img/svg/informational.svg") no-repeat;
        width: 40px;
        height: 34px;
        display: inline-block;
        background-size: contain;
        margin-right: 16px;
    }
}
